import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import Header from "../Components/Header/Header";
import MenuNavigationHeader from "../Components/MenuNavigationHeader/MenuNavigationHeader";
import ProductCard from "../Components/ProductCard/ProductCard";
import MenuCard from "../Components/MenuCard/MenuCard";
import Cart from "../Components/Cart/Cart";
import Hour from "../Components/Hour/Hour";


function Menu({setProductOpen}) {

  // const hostname = "http://localhost:5000";
  const hostname = "https://heed.herokuapp.com";

  // Action states
  const [showCategories, setShowCategories] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showCart, setShowCart] = useState(false);

  // Retailer
  const [title, setTitle] = useState("");
  const [banner, setBanner] = useState("")
  const [hours, setHours] = useState({});
  const [location, setLocation] = useState({});

  // Catalogue products
  const [categories, setCategories] = useState([]);
  const [catalogues, setCatalogues] = useState([]);
  const [titles, setTitles] = useState([]);
  const [products, setProducts] = useState([]);

  const [showSearch, setShowSearch] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [onBlur, setOnBlur] = useState(true);


  useEffect(() => {
    // Fetch products for diff modes
    fetch( `${hostname}/api/retailer/61239787a61dca00a274ac5f`, {    headers: { 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
    .then((response) => response.json())
    .then((response) => {
        if (response.success) {
            setLocation(response.data[0].location);
            setHours(response.data[0].hours);
            setTitle(response.data[0].catalogue.title);
            if (response.data[0].catalogue.banner) {
              setBanner(response.data[0].catalogue.banner);
            }
            setCategories(response.data[0].catalogue.categories);
            setCatalogues(response.data[0].catalogue.catalogue);
            setTitles(response.data[0].catalogue.catalogue.map(item => item.title));
        }
    })
    .catch((err) => {console.log(err)}); 
  }, [])



  useEffect(() => {
    var getProducts = (catalogue) => {
      return new Promise((resolve, reject) => {
        console.log(catalogue)
        let ids = catalogue.products.map(product => product.id);
        fetch( `${hostname}/api/menu/61239787a61dca00a274ac5f/products`, {    headers: { 'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify({filters: {ids: ids}}), crossDomain:true })
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                resolve(response.data);
            } else {
              resolve([]);
            }
        })
        .catch((err) => {
          resolve([]);
        }); 
      })
    }

    Promise.all(catalogues.map((catalogue => {
      return getProducts(catalogue);
    }))).then(values => {
      setProducts(values);
    });

    // for (var i = 0; i < catalogues.length; i++) {
    //   let ids = catalogues[i].products;
    // }


  }, [catalogues]);


  useEffect(() => {
    if (onBlur) {
      setShowSearch(false);
    } else {
      if (searchQuery !== "") {
        setShowSearch(true);
      }
    }
  }, [onBlur]);



  useEffect(() => {
    if (!showSearch && searchQuery !== "") {
      setShowSearch(true);
    }
  }, [searchQuery]);


  return (
    <div className="App">   

      {!showCategories && !showInfo && Object.keys(hours).length !== 0 ? (
        <Header hours={hours} showCart={showCart} setShowCart={setShowCart} showCategories={showCategories} setShowCategories={setShowCategories} showInfo={showInfo} setShowInfo={setShowInfo} />
      ) : null }

      {
        showCategories || showInfo && Object.keys(hours).length !== 0 ? (
          <div className="Menu-Navigation">
            <MenuNavigationHeader hours={hours} showCategories={showCategories} setShowCategories={setShowCategories} showInfo={showInfo} setShowInfo={setShowInfo}/>
            <div className="Menu-Navigation-Body">
              
              <div className="Menu-Navigation-Pages">
                <div onClick={() => {setShowInfo(false);setShowCategories(true)}}>
                  <span className={showCategories ? "active" : ""}>Categories</span>
                </div>
                <div onClick={() => {setShowCategories(false);setShowInfo(true);}}>
                  <span className={showInfo ? "active" : ""}>Info</span>
                </div>
              </div>

              {showCategories ? (
                <div className="Menu-Navigation-Categories">
                  <ul>
                    <li onClick={() => {window.location.href = "/products/flower"}} style={{color: "#6F6F6F"}}>Flower</li>
                    <li onClick={() => {window.location.href = "/products/prerolls"}} style={{color: "#6F6F6F"}}>PreRolls</li>
                    <li onClick={() => {window.location.href = "/products/edible"}} style={{color: "#6F6F6F"}}>Edibles</li>
                    <li onClick={() => {window.location.href = "/products/concentrate"}} style={{color: "#6F6F6F"}}>Concentrate</li>
                    <li onClick={() => {window.location.href = "/products/vaporizers"}} style={{color: "#6F6F6F"}}>Vaporizers</li>
                    <li onClick={() => {window.location.href = "/products/topicals"}} style={{color: "#6F6F6F"}}>Topicals</li>
                    <li onClick={() => {window.location.href = "/products/brands"}} style={{color: "#6F6F6F"}}>Brands</li>
                    <li onClick={() => {window.location.href = "/products/accessories"}} style={{color: "#6F6F6F"}}>Accessories</li>
                  </ul>
                </div>

              ) : (
                <div style={{maxWidth:970, margin:'auto'}}>
                  <div style={{marginTop:30, width:"100%"}}>
                    <iframe width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=9952%20Glendon%20Dr%20Komoka,%20ON+(Neat%20Cannabis)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                    </iframe>
                  </div>

                  <div className="Menu-Navigation-Info">                  
                  <div className="Info-Box">
                      <h5>Hours</h5>
                      <div style={{marginTop:0,display: 'flex', flexDirection:'row'}}>
                        <div style={{flexDirection:'column',marginRight:30}}>
                        <h6>Day</h6>
                        <ul className="list-group">
                          <li>Sunday</li>
                          <li>Monday</li>
                          <li>Tuesday</li>
                          <li>Wednesday</li>
                          <li>Thursday</li>
                          <li>Friday</li>
                          <li>Saturday</li>
                        </ul>
                        </div>

                        <div style={{flexDirection:'column'}}>
                          <h6>Pickup</h6>
                          <ul className="list-group">
                            {Object.keys(hours).map((keyName, i) => (
                                <li> 
                                {
                                    hours[keyName].display === true ? (
                                        <>
                                            <Hour time={hours[keyName].open} />
                                            -
                                            <Hour time={hours[keyName].close} />
                                        </>
                                    ) : <span style={{color:'red'}}>
                                            CLOSED
                                        </span>
                                
                                } 
                           </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    
                    </div>

                    <div className="Info-Box">
                      <h5>Location</h5>
                      <p>{location.address} {location.city}, {location.state}</p>
                      <button onClick={() => {window.open(`http://maps.google.com/?q=${location.address} ${location.city}, ${location.state}`, '_blank')}}>Get Directions</button>
                    </div>

                  </div>



                </div>
               
              )}
          

            </div>

          </div>
        ) : null
      }

      <div style={{display: showCategories || showInfo ? "none": "block"}} className="App-Body">
  
        <Cart showCart={showCart} setShowCart={setShowCart}/>

        <div className="Retailer-Branding">
          {
            banner !== "" ? (
              <img className="Banner" src={banner}></img>
            ) : null
          }
          <h1>{title}</h1>
          <input 
            value={searchQuery}
            onChange={(e) => {
                setSearchQuery(e.target.value);

                // Fetch request
                fetch( `${hostname}/api/menu/61239787a61dca00a274ac5f/searchProduct?q=${searchQuery}`, {    headers: { 'Content-Type': 'application/json' }, method: "GET"})
                .then((response) => response.json())
                .then((response) => {
                    if (response.success) {
                        setSearchResults(response.data);
                    }
                })
                .catch((err) => {console.log(err)}); 
            }}

            onFocus={(e) => {
              console.log("in focus!");
              setTimeout(() => {
                setOnBlur(false);
              }, 0);
            }}

            onBlur={(e) => {
              setTimeout(() => {
                setOnBlur(true);
              }, 500);
            }}

            style={{textAlign:'left'}} placeholder="Search..." className="Product-Search" type="text"></input>

            {
              showSearch  ? (
                <div className="Search-Results">
                  {/* <p style={{margin:0, marginTop:5}}>Search results...</p> */}
                  {searchResults.map(result => {
                    return (

                        <div id={result.id} onClick={(e) => {setTimeout(() => {
                          setOnBlur(false);
                          window.location.href = `/product/${result.id}`
                        }, 0)}} style={{paddingTop:8, paddingBottom:8, paddingLeft:5, paddingRight:15, background: "#FFF",  borderBottom:'none', cursor:'pointer', marginTop:0, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                            <img style={{width:40, height:40, marginRight:15}} src={result.Image}></img>
                            <div style={{width:"100%", maxWidth:130, display:"flex", flexDirection:"column", textAlign:'left'}}>
                                <h5 style={{color: "#A6A6A6",fontSize:12, fontWeight:600, marginTop:0, marginBottom:0}}>{result.brandName}</h5>
                                <p style={{color: "#000", fontSize: 12, margin:0}}>{result.Name}</p>
                            </div>
                            <div style={{marginLeft: 'auto', display:"flex", flexDirection:'column'}}>
                            {result.OverridePrice ? (
                                  <>
                                      <span style={{marginRight:5, color: "red", textDecoration: "line-through"}}>
                                          ${result.Prices[0].toFixed(2)}
                                      </span>
                                      ${result.OverridePrice.toFixed(2)}
                                  </>
                              ) : 
                                  <>
                                      ${result.Prices[0].toFixed(2)}
                                  </>
                              }

                            </div>
                        </div>
                    )
                  })}
                </div>
              ) : null
            }

        </div>


        <div className="Menu-Stack">


          {
            categories.map((value, index) => {
              return (
                <>
                  <h5 className="Product-Stack-Title">{value.title}</h5>
                  <div style={{textAlign:'center', justifyContent:'space-between'}} className="Feeling-Stack">
                    {value.values.map(category => {
                      return (
                        <MenuCard data={category} />
                      )
                    })}
                  
                  </div>
                </>
              )
            })
          }

          {
            titles.map((value, index) => {
              return (
                  <>
                  {products[index] && products[index].length > 0 ? (
                    <>
                    <h5 className="Product-Stack-Title">{value}</h5>
                    <div className="Product-Stack">
                      {products[index] ? (
                        <>
                          {products[index].map(product => {
                            return (
                              <Link to={`/product/${product.id}`}>
                                <ProductCard product={product} />
                              </Link>
                            )
                          })}
                        </>
                      ): null}
                     
                    </div>
                    </>
                  ): null }
                </>
              )
            })
          }


        </div>

      </div>

      <div className="Footer">
        <a href="https://heed.chat" target="_blank">Powered by <span style={{color:"#E17E76", fontWeight:"bold"}}>heed</span></a>
      </div>

    </div>
  );
}

export default Menu;


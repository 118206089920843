import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Products from "./Pages/Products";
import Order from "./Pages/Order";
import Product from "./Pages/Product";
import Menu from "./Pages/Menu";
import Checkout from "./Pages/Checkout";
import './App.css';

export default function App() {
  return (
    <Router>
        <Routes>
          <Route path="/order/:id" element={<Order />} />
          <Route path="/products/:page" element={<Products />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/checkout" element={<Checkout/>} />
          <Route path="/" element={<Menu/>} />
        </Routes>
    </Router>
  );
}
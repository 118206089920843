import React, { useEffect, useState, useRef } from "react";
import "./Header.css"
import CartIcon from "./CartIcon.png";
import HeedIcon from "./HeedIcon.png";
import MenuIcon from "./MenuIcon.png";
import InfoIcon from "./InfoIcon.png";

const Header = ({ hours, showCart, setShowCart, showCategories, setShowCategories, showInfo, setShowInfo }) => {

    const [open, setOpen] = useState(true);
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        var cartStorage = JSON.parse(localStorage.getItem('heed-cart'));
        if (cartStorage) {
            let amount = 0;
            Object.values(cartStorage).map((product, i) => {
                amount+=(1 * product.length);
            });
            setQuantity(amount);
        } else {
            setQuantity(0);
        }

        let hourDates = Object.keys(hours);
        const d = new Date();
        let day = d.getDay();
        var hour = d.getHours();
        var minute = d.getMinutes();

        var currentTime = `${hour}:${minute}:00`;
        var openTime = `${hours[hourDates[day]].open[0].toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${hours[hourDates[day]].open[1].toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:00`;
        var closeTime = `${hours[hourDates[day]].close[0].toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${hours[hourDates[day]].close[1].toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:00`;
        var storeOpenToday = hours[hourDates[day]].display;
        
        if (storeOpenToday) {
            if (openTime <= currentTime && currentTime <= closeTime) {
                console.log("location is opened!");
                setOpen(true);
            } else {
                console.log("location is closed!");
                setOpen(false);
            }
        } else {
            console.log("location is closed!");
            setOpen(false);
        }



        setTimeout(checkCart, 50);
    
    }, []);

    const checkCart = () => {
        var cartStorage = JSON.parse(localStorage.getItem('heed-cart'));
        if (cartStorage) {
            let amount = 0;
            Object.values(cartStorage).map((product, i) => {
                amount+=(1 * product.length);
            });
            if (amount != quantity) {
                setQuantity(amount);
            }
        } else {
            setQuantity(0);
        }

        setTimeout(checkCart, 50);
    }

    return (
        <div className="Header">
            <div className="Header-Container">
                {/* Desktop header components */}
                
                <div className="Desktop-Header">
                    <a href="/" style={{marginRight:15}} className="Header-Link">Home</a>
                    
                    <div style={{marginRight:15}} className="dropdown">
                        <button className="dropbtn Header-Link">Categories</button>
                        <div className="dropdown-content">
                            <a onClick={() => {window.location.href = "/products/flower"}} style={{color: "#6F6F6F"}}>Flower</a>
                            <a onClick={() => {window.location.href = "/products/prerolls"}} style={{color: "#6F6F6F"}}>PreRolls</a>
                            <a onClick={() => {window.location.href = "/products/edible"}} style={{color: "#6F6F6F"}}>Edibles</a>
                            <a onClick={() => {window.location.href = "/products/concentrate"}} style={{color: "#6F6F6F"}}>Concentrate</a>
                            <a onClick={() => {window.location.href = "/products/vaporizers"}} style={{color: "#6F6F6F"}}>Vaporizers</a>
                            <a onClick={() => {window.location.href = "/products/topicals"}} style={{color: "#6F6F6F"}}>Topicals</a>
                            <a onClick={() => {window.location.href = "/products/accessories"}} style={{color: "#6F6F6F"}}>Accessories</a>
                        </div>
                    </div>


                    <a href="/products/brands" style={{marginRight:15}} className="Header-Link">Brands</a>
                </div>

                <div style={{display: 'flex', alignItems:'center'}} className="Desktop-Header">
                    <span className={`Menu-Status ${open ? "green" : "red"}`}>{open ? "OPEN" : "CLOSED - Pre Orders Available"}</span>
                    <button onClick={() => {setShowInfo(!showInfo)}} className="Menu-Info">
                        <img src={InfoIcon}></img>
                    </button>
                    <button onClick={() => {setShowCart(!showCart)}} className="Cart-Button">
                        <div className="Cart-Quantity">{quantity}</div>
                        <img style={{width: 30, height:30}} src={CartIcon}/>
                    </button>

                </div>

        

                {/* Mobile Header components */}
                <button className="Mobile-Header" onClick={() => {setShowCategories(!showCategories)}} style={{cursor:"pointer", border: 'none', backgroundColor:'transparent'}}>
                    <div style={{ marginLeft:-5, display:'flex', alignItems:'center'}}>
                        <img style={{width: 25, height:25, marginRight:10}} src={MenuIcon}/>
                        <span className="Menu-Title">Menu</span>
                        <span className={`Menu-Status ${open ? "green" : "red"}`}>{open ? "OPEN" : "CLOSED - Pre Orders Available"}</span>
                    </div>
                </button>
                <div className="Mobile-Header" style={{display:'flex', alignItems:'center'}}>
                    <button className="Cart-Button" onClick={() => {setShowCart(!showCart)}} >
                        <div className="Cart-Quantity">{quantity}</div>
                        <img style={{width: 30, height:30}} src={CartIcon}/>
                    </button>
                </div>
            </div>            
        </div>
    )
}

export default Header